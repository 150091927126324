<ng-template #header>
  <app-header-static-image
    *ngIf="gymLegacyRO?.successful; else loadingTemplate"
    class="gym-header"
    [imageUrl]="imageHeader"
    [pageTitle]="gym.contactInfo.name | removeBrand"
    [unfixedHeight]="true"
  >
    <button
      class="btn btn-primary btn-join align-self-center mt-3 mt-md-n3"
      [class.invisible]="gymLegacyRO.response.id == BT_KIDS_PLAN"
      (click)="scrollToMembership()"
    >
      {{ gymLegacyRO.response.preSale ? 'Pré-Matrícula' : config?.header?.buttonText || 'Matricule-se' }}
    </button>
  </app-header-static-image>
</ng-template>
<main class="main-wrapper container">
  <h2 class="title-lg text-center">Conheça a Academia</h2>

  <section class="row no-gutters pt-3 mt-2 mt-lg-3 row-on-xs" *ngIf="gymLegacyRO?.successful; else loadingTemplate">
    <!-- DETAILS -->
    <div class="col-12 col-md-6 pr-md-3 mb-3 mb-md-0">
      <div class="card no-side-border-xs">
        <div class="d-flex flex-column justify-content-center bg-secondary position-relative">
          <!-- <agm-map
            #map
            [clickableIcons]="false"
            [disableDefaultUI]="true"
            [disableDoubleClickZoom]="true"
            [keyboardShortcuts]="false"
            [latitude]="gym.coordinates ? gym.coordinates.latitude + .0008 : 0"
            [longitude]="gym.coordinates ? gym.coordinates.longitude : 0"
            [mapDraggable]="false"
            [scrollwheel]="false"
            [style.height.px]="220"
            [zoom]="17"
          >
            <agm-marker
              iconUrl="assets/icons_custom/ic-link-location@2x.png"
              [latitude]="gym.coordinates ? gym.coordinates.latitude : 0"
              [longitude]="gym.coordinates ? gym.coordinates.longitude : 0"
            ></agm-marker>
          </agm-map> -->
          <div #map id="map"></div>

          <div class="map-card-wrapper position-absolute d-flex align-items-end w-100">
            <div class="map-card position-relative mx-auto p-3 bg-white text-center">
              <div class="title font-weight-normal mb-2">
                {{ brandService.fullBrandName }} {{ gym.contactInfo.name | removeBrand }}
              </div>

              <a
                class="d-flex justify-content-center link-small"
                target="_blank"
                [href]="gym.googleLink"
              >
                <img class="mr-1" src="assets/icons_custom/ic-link-location.svg" alt="Ícone de localização">
                <span>COMO CHEGAR</span>
              </a>
            </div>
          </div>
        </div>

        <div class="gym-info-container">
          <app-gym-contact-info
            *ngIf="!gym.evoMigratedUnit || contactInfoRO?.successful"
            [contactInfo]="gym.contactInfo"
          ></app-gym-contact-info>

          <ng-template [ngTemplateOutlet]="loadingTemplate" *ngIf="contactInfoRO?.loading"></ng-template>

          <app-error *ngIf="contactInfoRO?.error"></app-error>

          <a
            *ngFor="let social of gym.gymUnitSocialMedias"
            class="d-block link-small text-lowercase mt-1"
            target="_blank"
            [href]="social.url"
          >
            <img
              class="mt-n2 mr-1 icon"
              [alt]="'Ícone do' + social.socialMedia.name"
              [src]="social.socialMedia.urlImage"
            >
            <span>{{ social.display }}</span>
          </a>

          <div class="mt-3">
            <app-gym-business-hours [businessHours]="gym.workingPeriods"></app-gym-business-hours>
          </div>
        </div>
      </div>
    </div>

    <!-- IMAGES -->
    <div class="col-12 col-md-6 pl-md-2 mt-3 mt-md-0" *ngIf="gym.photos">
      <app-gym-images-carousel
        [images]="gym.photos"
        [gymName]="gym.contactInfo.name"
      ></app-gym-images-carousel>
    </div>
  </section>

  <!-- SPECIAL SALE -->
  <section class="mt-4 pt-lg-2" *ngIf="gym?.activePromotion">
    <div class="card no-side-border-xs d-flex flex-column flex-md-row row-on-xs">
      <div class="promo-icon d-flex justify-content-center align-items-center">
        <img src="/assets/icons_custom/ic-default-promo.svg">
      </div>

      <div class="d-flex flex-column justify-content-center p-3">
        <div class="title-md font-weight-normal">{{ gym.activePromotion.purchaseTitle }}</div>
        <div class="mt-2" [innerHTML]="gym.activePromotion.purchaseText | safeHtml"></div>
      </div>
    </div>
  </section>

  <!-- MEMBERSHIP -->
  <section #membershipSection>
    <h2 class="title-lg text-center mb-3 pb-3">Escolha seu plano</h2>

    <app-memberships
      *ngIf="membershipsRO && !membershipsRO.loading else loadingTemplate"
      [memberships]="membershipsRO?.response"
      (clickShowMore)="scrollToMembership($event)"
    ></app-memberships>
  </section>

  <!-- ACTIVITIES -->
  <section class="gym-services row-on-xs">
    <h2 class="title-lg hide-on-bt text-center mb-3 pb-3">Atividades</h2>

    <app-gym-experience
      *ngIf="gymLegacyRO?.successful; else loadingTemplate"
      [legacyGym]="gymLegacyRO.response"
      [migratedGym]="gym"
      [loadingLabel]="config.results.loading"
    ></app-gym-experience>
  </section>

  <!-- SCHEDULE -->
  <section>
    <h2 class="agenda-title title-lg text-center">Horários das Aulas</h2>

    <ng-container *ngIf="gym?.evoMigratedUnit; else scheduleFilter">
      <p class="text-center mb-5">Acesse a agenda da academia e veja os horários das atividades desejadas.</p>

      <div class="pt-3 d-flex justify-content-center">
        <button
          class="btn btn-primary btn-confirm"
          (click)="evoGeneralService.openEvoSchedule(gym.code)"
        >
          VER AGENDA
        </button>
      </div>
    </ng-container>

    <ng-template #scheduleFilter>
      <app-agenda-filter
        *ngIf="gymLegacyRO.successful; else loadingTemplate"
        analyticsCategory="Vendas"
        [allActivities]="gymLegacyRO.response.activities"
        [analyticsTag]="'Detalhes Academia - ' + gymLegacyRO.response.portalName"
        [gym]="gymLegacyRO.response"
      ></app-agenda-filter>
    </ng-template>
  </section>
</main>

<ng-template #loadingTemplate>
  <app-loading-content [loadingLabel]="config.results.loading" classes="text-center"></app-loading-content>
</ng-template>